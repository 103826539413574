<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>
    <v-card :elevation="isEmbedded ? 0 : 2">
      <EventHeader v-if="!isEmbedded && event && race" :event="event" :title="race.name" compact/>
      <v-card-title v-if="!isEmbeddedInApp" class="subtitle">{{$t('profile.activities.entry.claim-achievement')}}</v-card-title>
      <v-alert v-if="claimableResponse && !claimableBadge" type="warning">{{$t('profile.activities.entry.no-claimable-achievements-msg')}}</v-alert>
      <v-container v-if="claimableBadge">
      <v-row>
        <v-col v-if="claimableBadge.img" class="shrink">
          <v-img color="grey" class="mt-1" width="100" style="min-width:100px" :src="claimableBadge.img"/>
        </v-col>
        <v-col class="grow">
          <h3>{{claimableBadge.name}}</h3>
          <vue-markdown v-if="claimableBadge.description" class="markdown" :html="false">{{claimableBadge.description }}</vue-markdown>
        </v-col>
      </v-row>
      </v-container>
      
      <v-form ref="form" v-model="valid" lazy-validation v-if="request">
        <v-card-text class="pb-0">     
          <v-alert v-if="error || !valid" type="error">{{error || $t('shared.validation-error-message') }}</v-alert>
          
          <h3>{{$t('profile.activities.entry.select-date-time')}}</h3>
          <DateAndTimeWithTimeZonePicker v-model="request.timestamp" ref="timePicker" :timeZone="timeZoneOlson" showQuickLinks :label="$t('profile.activities.entry.activity-date-start')" :timeLabel="$t('profile.activities.entry.activity-time-start')" />

          <h3 class="mt-4">Upload a photo</h3>
          <p class="mb-0">Please select a photo or selfie to upload with this achievement.</p>
          <photo-picker @submit="savePhoto"/>
          <v-alert v-if="formDataWithPhoto" type="success">{{$t('profile.activities.entry.photo-ready-for-upload')}}</v-alert>
          <v-checkbox v-if="formDataWithPhoto" v-model="request.post_feed" :label="$t('profile.upload-photo.share-in-feed')" />

        </v-card-text>      
        
        <v-card-text>   
          <v-checkbox v-model="addAnotherAfterSave" :label="$t('profile.activities.entry.add-another')" />
          <v-alert v-if="infoMessage" class="mt-4" type="info">
            <vue-markdown class="markdown" :html="false" :source="infoMessage" />
          </v-alert>
          <v-btn v- block :disabled="!valid || !requestValid || !claimableBadge" color="primary" :loading="$store.getters.isLoading" @click="submit(false)">{{$t('shared.save')}}</v-btn>
        </v-card-text>   
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import { Interval, DateTime } from 'luxon';
import siteData from '@/data/site.json';
import eventService from "@/services/eventService";
import profileService from "@/services/profileService";
import activityService from "@/services/activityService";
import EventHeader from "@/components/EventHeader.vue";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import BigButton from '@/components/BigButton.vue';
import PhotoPicker from '@/components/PhotoPicker.vue';
import VueMarkdown from '@/components/VueMarkdown.vue';

export default {
  name: "ClaimAchievementConfirm",
  components: {
    VueMarkdown,
    EventHeader,
    DateAndTimeWithTimeZonePicker,
    BigButton,
    PhotoPicker,
  },
  props: {
  },
  data() {
    return {
      event: null,
      race: null,
      request: null,
      claimableResponse: null,
      claimBadgeId: null,
      timeZoneOlson: null,
      error: null,
      valid: true,
      siteData: siteData,
      validationError: false,
      infoMessage: null,
      addAnotherAfterSave: false,
      formDataWithPhoto: null,
      breadcrumbItems: [
        { text: this.$t('profile.title'), href: '/profile' },
        { text: this.$t('profile.activities.title'), href: '/profile/activities' },
        { text: this.$t('profile.activities.entry.claim-achievement'), disabled: true },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    this.timeZoneOlson = DateTime.now().zoneName;
    this.claimBadgeId = this.$route.query.badgeId;
    this.createNewRequest();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });

  },
  methods: {
    async createNewRequest() {
      this.request = {
        timestamp: null,//DateTime.now().startOf('day').toISO(),
        post_feed: false,
        badge_id: this.claimBadgeId,
      };
    },

    async loadData() {
      if (this.user) {
        this.event = (await eventService.get(this.$route.params.eventId)).data;
        this.race = this.event.races.find(x => x.id == this.$route.params.raceId);
        this.claimableResponse = (await activityService.getClaimableBadges(this.$route.params.eventId, this.$route.params.raceId)).data;
      }
    },

    async savePhoto(formData) {
      this.formDataWithPhoto = formData;
      // todo: now what?
      //var response = await profileService.uploadPhoto(formData, false);
      //return response;
    },

    async submit(ignoreValidation) {
      if (this.$refs.form.validate()) {
        this.error = null;
        this.infoMessage = null;
        this.validationError = false;

        try {
          console.log('Storing manual entry', this.request);
      // todo: merge formDataWithPhoto in (if set)
          var formData = this.formDataWithPhoto || new FormData();
          formData.append("badge_id", this.request.badge_id);
          formData.append("timestamp", this.request.timestamp);
          formData.append("post_feed", this.request.post_feed);

          var data = (await activityService.claimBadge(this.$route.params.eventId, this.$route.params.raceId, formData)).data;
          if (data.status === "OK"){
            if (this.addAnotherAfterSave) {
              this.$helpers.toastResponse(this, {status: 'OK'}, this.$t('profile.activities.entry.uploaded-success'));
              this.$router.push({ name: 'activityClaim', params: { eventId: this.$route.params.eventId, raceId: this.$route.params.raceId } });
            } else {
              this.$router.push({ name: 'activity', params: { id: data.id }, query: { appcmd: 'close', apphint: 'claim-achievement-success' } });
            }
          }
          else if (data.status === "INFO") {
            this.infoMessage = data.msg;
            this.validationError = true;
          }
          else {
            this.error = data.msg;
            this.validationError = true;
          }
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
      }
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    }),

    claimableBadge() {
      return this.claimableResponse && this.claimableResponse.claimable && this.claimableResponse.claimable.find(x => x.id == this.claimBadgeId);
    },
    
    requestValid() {
      return this.request.badge_id && this.request.timestamp;
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
  },
  watch: {
    claimableBadge() {
      //console.log('/// badge', this.claimableBadge);
      if (this.claimableBadge && this.claimableBadge.from && this.claimableBadge.till) {
        var from = DateTime.fromISO(this.claimableBadge.from);
        var till = DateTime.fromISO(this.claimableBadge.till);
        var interval = Interval.fromDateTimes(from, till);
      //console.log('getStartDurationForEvent', interval, interval.length('hours'));
      console.log('/// from', from.toISODate(), from.toISO(), from.startOf('day').toISO());
        if (interval.length('hours') <= 24) {
          this.request.timestamp = from.startOf('day').toISO();
        }
      }
    }
  }
};
</script>
<style lang="scss">
</style>

